import request from '@/utils/request'

const public_area_feeApi = {
  public_area_fee_list: '/public_area_fee/',
  public_area_fee_create: '/public_area_fee/',
  public_area_fee_update: '/public_area_fee/',
  public_area_fee_delete: '/public_area_fee/'
}

/**
 * 列表
 */
export function public_area_fee_list (parameter) {
  return request({
    url: public_area_feeApi.public_area_fee_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function public_area_fee_create (parameter) {
  return request({
    url: public_area_feeApi.public_area_fee_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function public_area_fee_update (parameter, public_area_fee_id) {
  return request({
    url: public_area_feeApi.public_area_fee_update + public_area_fee_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function public_area_fee_delete (public_area_fee_id) {
  return request({
    url: public_area_feeApi.public_area_fee_delete + public_area_fee_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
