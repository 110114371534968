<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="类型">
              <a-select default-value="" style="width: 100%" v-model="queryParam.fee_type" allowClear placeholder="请选择" >
                <a-select-option v-for="(val, key) in this.$Dictionaries.public_area_fee_type" :value="key" :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="位置">
              <a-select default-value="" style="width: 100%" v-model="queryParam.location" allowClear placeholder="请选择">
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所有人">
              <a-select default-value="" style="width: 100%" v-model="queryParam.property_owner" allowClear placeholder="请选择" >
                <a-select-option v-for="(val, key) in this.$Dictionaries.property_owner" :value="key" :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ text }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:edit>编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            v-action:delete
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { public_area_fee_create, public_area_fee_list, public_area_fee_delete } from '@/api/public_area_fee'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/RecordCreateForm'
import { water_bill_create } from '@/api/water'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      rules: {
        batch: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      form: {
        batch: '',
        start_date: '',
        end_date: ''
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      billLoading: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          customRender: (text) => this.$Dictionaries.public_area_fee_type[text]
        },
        {
          title: '应收金额',
          dataIndex: 'total_income',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '实收金额',
          dataIndex: 'actual_income',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '位置',
          dataIndex: 'location',
          customRender: (text) => this.$Dictionaries.location.find((item) => !!item[text])[text]
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          customRender: (text) => this.$Dictionaries.property_owner[text]
        },
        {
          title: '开始时间',
          dataIndex: 'start_date',
          ellipsis: true
        },
        {
          title: '结束时间',
          dataIndex: 'end_date',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return public_area_fee_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    // 按照时间创建租金账单
    handleWaterOk () {
      if (!this.form.batch) {
        this.$message.error('表单不能为空')
        return
      }
      // const param = Object.assign({}, { ...this.form })
      water_bill_create({ ...this.form }).then((res) => {
        console.log(res)
        if (res.success) {
            this.$message.success('本次生成' + res.data + '条账单')
        }
        this.billLoading = false
          this.form.batch = ''
          this.form.start_date = ''
          this.form.end_date = ''
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      public_area_fee_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk (date) {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors && date) {
          values['date'] = undefined
          console.log('values', values, date)
          values['total_income'] = Math.round(values['total_income'] * 100)
          values['actual_income'] = Math.round(values['actual_income'] * 100)
          values['start_date'] = date.start_date
          values['end_date'] = date.end_date
          // 新增
          public_area_fee_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

            console.log('删除成功------')
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
