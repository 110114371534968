<template>
  <div>
    <a-form :form="form">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="类别" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-select
              @change="handleCategoryChange"
              v-decorator="[
                'fee_type',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]"
              placeholder="选择"
            >
              <a-select-option
                v-for="(val, key) in this.dictionaries.public_area_fee_type"
                :value="key"
                :key="key">
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="位置" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-select
              @change="handleLocationChange"
              v-decorator="[
                'location',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]"
              placeholder="选择"
            >
              <a-select-option
                v-for="(val, key) in this.dictionaries.CorporationLocation"
                :value="key"
                :key="key">
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所有人" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-select
              @change="handlePropertyOwnerChange"
              v-decorator="[
                'property_owner',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]"
              placeholder="选择"
            >
              <a-select-option
                v-for="(val, key) in this.dictionaries.property_owner"
                :value="key"
                :key="key">
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="日期" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%" v-decorator="['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
              <template slot="dateRender" slot-scope="current">
                <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                  {{ current.date() }}
                </div>
              </template>
            </a-range-picker>
          </a-form-item>
          <a-form-item label="应收金额" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-input-number
              :min="0"
              :precision="2"
              v-decorator="['total_income', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
              placeholder="请输入"
              style="width: 180px;"
            />
          </a-form-item>
          <a-form-item label="实收金额" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
            <a-input-number
              :min="0"
              :precision="2"
              v-decorator="['actual_income', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
              placeholder="请输入"
              style="width: 180px;"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            v-bind="buttonCol"
          >
            <a-row>
              <a-col span="8"></a-col>
              <a-col span="6">
                <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
              </a-col>
              <a-col span="10">
                <a-button @click="handleGoBack">返回</a-button>
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { public_area_fee_update } from '@/api/public_area_fee'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      dictionaries: this.$Dictionaries,
      category: 0,
      location: 0,
      property_owner: 0,
      formData: {}
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
          values['date'] = undefined
          values['total_income'] = Math.round(values['total_income'] * 100)
          values['actual_income'] = Math.round(values['actual_income'] * 100)
          values['start_date'] = this.formData.start_date
          values['end_date'] = this.formData.end_date
          public_area_fee_update(values, this.id)
            .then((res) => {
              if (res.code === 1000) {
                this.handleGoBack()
                console.log(res, '修改成功')
              }
            })
        }
      })
    },
    handleGetInfo () {
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['fee_type', 'location', 'property_owner', 'total_income', 'actual_income', 'date'])
        console.log('formData', formData)
        formData.fee_type = formData.fee_type + ''
        formData.location = formData.location + ''
        formData.property_owner = formData.property_owner + ''
        formData.total_income = formData.total_income * 0.01
        formData.actual_income = formData.actual_income * 0.01
        formData.date = [ moment(data.start_date), moment(data.end_date) ]
        this.id = data.id
        form.setFieldsValue(formData)
      })
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.formData.start_date = dateStrings[0]
      this.formData.end_date = dateStrings[1]
    },
    handleCategoryChange (value) {
      this.category = Number(value)
      console.log(value)
      if (this.category === 2) {
        this.content_placeholder = '付款说明'
      } else {
        this.content_placeholder = '请输入说明'
      }
      if (this.category === 3) {
        this.$nextTick(() => {
          this.category_fee_disabled = true
          this.form.setFieldsValue({ 'category_fee': '1' })
        })
      } else {
        this.$nextTick(() => {
          this.category_fee_disabled = false
          this.form.setFieldsValue({ 'category_fee': undefined })
        })
      }
    },
    handleLocationChange (value) {
      this.location = Number(value)
      console.log(value)
    },
    handlePropertyOwnerChange (value) {
      this.property_owner = Number(value)
      console.log(value)
    }
  }
}
</script>
